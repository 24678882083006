<template>
		<div>
			<h2>{{ $t("check.liste_cheques") }}</h2>

		    <form class="mb-4">
		        <div class="form-group">
		            <e-select
		                v-model="form.checkdeposit_accountingplan"
		                id="accountingaccount_vat"
		                track-by="name"
		                label="name"
		                :placeholder="labelTitleAccoutingPlan"
		                :deselectLabel="deselectLabel"
		                :selectedLabel="selectedLabel"
		                :selectLabel="enter_to_select"
		                :options="accounting_plans"
		                :loading="isLoading"
		                :searchable="true"
		                :show-labels="false"
		                :allow-empty="false"
		            >
		                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
		                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
		            </e-select>
		        </div>

		        <div class="form-group">
		            <e-select
		                v-model="form.checkdeposit_configuration"
		                id="accountingaccount_vat"
		                track-by="name"
		                label="name"
		                :placeholder="labelTitleConfiguration"
		                :deselectLabel="deselectLabel"
		                :selectedLabel="selectedLabel"
		                :selectLabel="enter_to_select"
		                :options="configurations"
		                :loading="isLoadingConfiguration"
		                :searchable="true"
		                :show-labels="false"
		                :allow-empty="false"
		            >
		                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
		                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
		            </e-select>
		        </div>

                <div class="form-group">
                    <e-select
                        v-show="show_payment_methods"
                        id="payment_method"
                        v-model="payment_method"
                        :placeholder="labelTitleMethods"
                        :deselectLabel="deselectLabel"
		                :selectedLabel="selectedLabel"
		                :selectLabel="enter_to_select"
                        :options="payment_methods"
                        track-by="paymentmethod_id"
                        label="translated_option"
                        :allow-empty="false"
                        :searchable="true"
                    />
                </div>

                <DateRangeV2
                    :start.sync="start_date"
                    :end.sync="end_date"
                    @submit="loadCheck"
                    :dateSelect=true
                />

		        <template v-if="all_checks.length > 0">
                    <CustomTable
                        id_table="check_deposit_paiement"
                        :items="all_checks"
                        primaryKey="payment_id"
                        :hide_if_empty="true"
                    />

                    <!-- checked_checks -->
		        </template>
                <template v-else-if="!this.form.checkdeposit_accountingplan || !this.payment_method">
                    <p>{{ $t('global.remplir_champ_obligatoire') }}</p>
                </template>
		        <template v-else-if="all_checks.length == 0 && all_checks_loading">
		            <LoadingSpinner />
		        </template>
		        <template v-else-if="all_checks.length == 0 && form.checkdeposit_accountingplan != null">
		            <p>{{ $t('check.no_cheque_sur_entite') }}</p>
		        </template>
		    </form>
		</div>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Accounting from "@/mixins/Accounting.js"
	import Shutter from "@/mixins/Shutter.js"

    export default {
        name: "checkDepositForm",
        mixins: [Navigation, Payment, Accounting, Shutter],
        props: [],
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitleAccoutingPlan: this.getTrad("compta.select_accounting_plan") + '*',
                labelTitleConfiguration: this.getTrad("check.select_configuration") + '*',
                labelTitleMethods: this.getTrad('payment.payment_method') + '*',
                check_deposit_status: this.getConfig('check_deposit_status'),
                check_deposit_status_format: [],
                check_deposit: null,

                table_busy: true,
                modal_loaded: false,
                processing: false,
                isLoading: false,
                isLoadingConfiguration: false,
                all_checks_loading: false,
                payment_methods: this.getConfig('payment_method'),
                payment_method: null,
                accounting_plans: [],
                configurations: [],
                errors: [],
                all_checks: [],
                checked_checks: [],
                payments_methods: [],
                payments_methods_ids: [],
                show_payment_methods: true,
                form: {
                    checkdeposit_date: new Date(),
                    checkdeposit_status: 1,
                    checkdeposit_accountingplan: null,
                    checkdeposit_configuration: null,
                    checkdeposit_ref: "",
                },
                events_tab: {
                    'TableAction::goToCheckDepositConfig': (params) => {
                        this.ok({params: params, form: this.form})
                    },
				},
                start_date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                end_date: new Date(),
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.addCheckDepositForm()

                // Formatage payment methods
				this.payment_methods.map((option) => {
					let translated_option = this.getTrad('payment.payment_method_labels.' + option.paymentmethod_code.toLowerCase())
					if (option.paymentmethod_code == 'CC') {
						const regExp = /\(([^)]+)\)/;
						const matches = regExp.exec(option.paymentmethod_label);
						if(matches && matches[0]){
							translated_option += ' ' + matches[0]
						}
					}
					option.translated_option = translated_option
					return option
				})
				this.show_payment_methods = false
				this.$nextTick(() => {
					this.show_payment_methods = true
				})
            },
            async addCheckDepositForm() {
                this.isLoading = true

                this.check_deposit_status_format = []

                for (let i = 0; i < this.check_deposit_status.length; i++) {
                    this.check_deposit_status_format.push({
                        text: this.getTrad(this.check_deposit_status[i].checkdepositstatus_label),
                        value: this.check_deposit_status[i].checkdepositstatus_id
                    })
                }


                let accounting_plan = await this.loadAccountingPlans()

                this.accounting_plans = []
                
                for (let i = 0; i < accounting_plan.length; i++) {
                    this.accounting_plans.push({
                        id: accounting_plan[i].accountingplan_id,
                        name: accounting_plan[i].accountingplan_label + " (" + accounting_plan[i].tiers.tiers_rs + ")",
                    })

                    if(accounting_plan.length == 1)
                    {
                        this.form.checkdeposit_accountingplan = {
                            id: accounting_plan[i].accountingplan_id,
                            name: accounting_plan[i].accountingplan_label + " (" + accounting_plan[i].tiers.tiers_rs + ")",
                        }
                    }
                }

                this.isLoading = false
            },

            async loadCheckDeposit() {
                this.configurations = []
                this.form.checkdeposit_configuration = null

                this.isLoadingConfiguration = true

                //On va chercher les configurations de remise par rapport à une compta
                this.getCheckDepositConfigListe(this.form.checkdeposit_accountingplan.id).then(config => {
                    for (let i = 0; i < config.length; i++) {
                        const libelle = (config[i].checkdepositconfiguration_libelle != null ) ? config[i].checkdepositconfiguration_libelle + " - " : ""
                        this.configurations.push({
                            name: libelle + config[i].checkdepositconfiguration_bank + " (" + config[i].checkdepositconfiguration_accountnumber + ")", 
                            id: config[i].checkdepositconfiguration_id
                        })

                        if(config.length == 1)
                        {
                            this.form.checkdeposit_configuration = this.configurations[0]
                        }
                    }

                    this.isLoadingConfiguration = false

                })  
            },

            async loadCheck() {
                if(!this.form.checkdeposit_accountingplan || !this.payment_method) {
                    return false
                }
                this.all_checks = []
                this.all_checks_loading = true
                this.payments_methods = []
                this.payments_methods_ids = []

                //On va chercher la liste des paiements
                this.loadAllCheck(this.form.checkdeposit_accountingplan.id, this.payment_method.paymentmethod_id, this.start_date.toDateInputValue(), this.end_date.toDateInputValue()).then(async result => {
                    for (let i = 0; i < result.length; i++) {
                        if(this.payments_methods_ids.indexOf(result[i].method.paymentmethod_id) === -1)
                        {
                            this.payments_methods_ids.push(result[i].method.paymentmethod_id)
                            this.payments_methods.push({
                                text: this.getTrad(result[i].method.paymentmethod_label),
                                value: result[i].method.paymentmethod_id
                            })
                        }

                        const payment_bankcheck = result[i].payment_bankcheck ? " - " + result[i].payment_bankcheck : ""

                        result[i].payment_amount = await this.priceFormat(result[i].payment_amount, result[i].invoice_currency, true)
                        this.all_checks.push(result[i])
                    }

                    this.all_checks_loading = false
                })
            }
        },
        computed: {
        	computed_checks: function(){
        	    if(this.errors && this.errors.indexOf('Check') > -1)
        	    {
        	        return false
        	    }

        	    return null
        	}
        },
        watch: {
            'form.checkdeposit_accountingplan'(val){
                if(val) {
                    this.loadCheckDeposit()
                }
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
        }
    }
</script>
